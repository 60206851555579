import store from "@/store";

const routes = [
	{
		path: '/orders',
		name: 'orders',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "orders" */ '../../views/orders'),
		meta: {
			title: 'Pedidos'
		}
	},
	{
		path: '/orders/create',
		name: 'orders.create',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "orders.edit" */ '../../views/orders/edit'),
		meta: {
			title: 'Criar Pedido'
		}
	},
	{
		path: '/orders/:id',
		name: 'orders.edit',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "orders.edit" */ '../../views/orders/edit'),
		meta: {
			title: 'Editar Pedido'
		}
	}
];

export default routes;
