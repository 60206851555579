<template>
	<toggle-button
        :value="value"
        @change="change($event)"
        :sync="true"
		:font-size="14"
		:height="30"
		:width="width"
        v-bind="$attrs"
	/>
    <!-- v-on="$listeners" -->
</template>

<script>
	export default {
		name: "EjToggleButton",

		props: {
            value: [Boolean],
			width: {
				type: Number,
				default: 70
			}
		},

        methods: {
            change(event) {
                this.$emit('change', event.value);
            }
        },

        model: {
            prop: 'value',
            event: 'change'
        },
	}
</script>

<style scoped>

</style>
