import router from "@/router";
import {exibeErro} from "@/helpers";

export default {
	namespaced: true,

	state: {
		user: undefined,
		permissions: undefined,
		settings: undefined,
	},

	mutations: {
		setUser(state, user) {
			state.user = user;
		},

		setPermissions(state, permissions) {
			state.permissions = permissions;
		},

		setSettings(state, settings) {
			state.settings = settings;
		}
	},

	actions: {
		async login({ state }, credentials) {
			state; //fixme

			try {
				await window.axios.get('/sanctum/csrf-cookie');
				await window.axios.post('/login?XDEBUG_SESSION_START=', credentials);
				await router.push({name: 'home'});

			} catch(error) {
				exibeErro(error);
			}
		},

		async logout({ commit }) {
			try {
				await window.axios.post('/logout');
				await commit(`setUser`, undefined);
				window.location = '/login';

			} catch(error) {
				exibeErro(error);
			}
		},

		async check({state, dispatch, commit}, next) {
			if (state.user) {
				next();
			} else {
				try {
					const me = (await dispatch(`users/me`, null, {root:true})).data;
					await commit(`setUser`, me);

					const permissions = me.permissions.reduce((result, item) => {
						result[item.name] = true;
						return result;
					}, {});
					await commit(`setPermissions`, permissions);


					const settings_data = (await dispatch(`settings/index`, {}, {root:true})).data;
					let settings = settings_data.data.reduce((result, item) => {
						result[item.key] = item.value;
						return result;
					}, {});
					await commit(`setSettings`, settings);

					next();
				} catch(error) {
					if (Object.prototype.hasOwnProperty.call(error, 'response') && error.response.status === 401)
						window.location = '/login';
					else
						exibeErro(error);
				}
			}
		}
	}
}
