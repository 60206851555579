<template>
	<div class="ej-page" v-bind="$attrs" v-on="$listeners">
		<ej-mobile-menu></ej-mobile-menu>

		<ej-desktop-header></ej-desktop-header>

		<slot></slot>

		<ej-footer></ej-footer>
	</div>
</template>

<script>
	import EjMobileMenu from "@/components/EjMobileMenu";
	import EjDesktopHeader from "@/components/EjDesktopHeader";
	import EjFooter from "@/components/EjFooter";

	export default {
		name: "EjPage",

		components: {
			EjMobileMenu,
			EjDesktopHeader,
			EjFooter
		}
	}
</script>

<style scoped>

</style>