<template>
	<span>*</span>
</template>

<script>
	export default {
		name: "EjAsterisk"
	}
</script>

<style scoped>

</style>
