import store from "@/store";

const routes = [
	{
		path: '/automessages',
		name: 'automessages',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "automessages" */ '../../views/automessages'),
		meta: {
			title: 'Mensagens Programadas'
		}
	},
	{
		path: '/automessages/create',
		name: 'automessages.create',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "automessages.edit" */ '../../views/automessages/edit'),
		meta: {
			title: 'Criar Mensagem Programada'
		}
	},
	{
		path: '/automessages/:id',
		name: 'automessages.edit',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "automessages.edit" */ '../../views/automessages/edit'),
		meta: {
			title: 'Editar Mensagem Programada'
		}
	},
	{
		path: '/automessages/:id/mailings',
		name: 'automessages.mailings',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "automessages" */ '../../views/automessages/mailings'),
		meta: {
			title: 'Envios'
		}
	},
];

export default routes;
