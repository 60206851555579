const routes = [
	{
		path: '/passwords/reset',
		name: 'passwords.reset',
		component: () => import(/* webpackChunkName: "passwords.reset" */ '../../views/passwords/reset'),
		meta: {
			title: 'Redefinir Senha',
			layout: 'center'
		}
	}
];

export default routes;
