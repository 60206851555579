<template>
	<div class="ej-buttons" v-bind="$attrs" v-on="$listeners">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: "EjButtons"
	}
</script>

<style scoped>

</style>