<template>
	<date-pick
		ref="pick"
		v-model="v"
		:weekdays="i18n.vueDatePicker.weekdays"
		:months="i18n.vueDatePicker.months"
		:nextMonthCaption="i18n.vueDatePicker.nextMonthCaption"
		:prevMonthCaption="i18n.vueDatePicker.prevMonthCaption"
		:setTimeCaption="i18n.vueDatePicker.setTimeCaption"
		:format="'YYYY-MM-DD'"
		:displayFormat="'DD/MM/YYYY'"
	>
<!--		v-bind="$attrs"-->
<!--		v-on="$listeners"-->
		<template
			v-slot:default="{ inputValue }"
		>
			<input
				ref="input"
				type="text"
				class="form-control"
				:value="inputValue"
				:placeholder="placeholder"
				@click="onClick"
				@focus="onFocus"
				@input="onInput"
			/>
		</template>
	</date-pick>
</template>

<script>
	/* Implementação baseada no vue-the-mask. Grande parte do código abaixo foi extraído do arquivo directive.js */
	import tokens from 'vue-the-mask/src/tokens';
	import masker from "vue-the-mask/src/masker";

	//DatePick
	import DatePick from 'vue-date-pick';
	import 'vue-date-pick/dist/vueDatePick.css';

	export default {
		name: "EjDatePicker",

		data() {
			return {
				v: undefined
			}
		},

		props: {
			value: [String],
			mask: {
				type: String,
				default: '##/##/####'
			},
			editable: {
				type: Boolean,
				default: true
			},
			placeholder: [String],
		},

		watch: {
			v(newValue) {
				this.$emit('change', newValue);
			},

			value(newValue) {
				this.v = newValue;
			}
		},

		model: {
			prop: 'value',
			event: 'change'
		},

		methods: {
			onClick() {
				this.$refs.pick.open();
			},

			onFocus() {
				this.$refs.pick.open();
			},

			onInput() {
				const el = this.$refs.input;

				// by default, keep cursor at same position as before the mask
				var position = el.selectionEnd
				// save the character just inserted
				var digit = el.value[position-1]
				el.value = masker(el.value, this.mask, true, tokens)
				// if the digit was changed, increment position until find the digit again
				while (position < el.value.length && el.value.charAt(position-1) !== digit) {
					position++
				}
				if (el === document.activeElement) {
					el.setSelectionRange(position, position)
					setTimeout(function () {
						el.setSelectionRange(position, position)
					}, 0)
				}

				this.$refs.pick.processUserInput(el.value);

				this.$emit('change', el.value);
			}
		},

		mounted() {
			this.v = this.value;
		},

		components: {
			DatePick
		}
	}
</script>

<style scoped>
	.vdpComponent {
		display: block;
	}
</style>