<template>
	<ej-button @click="openMenu" v-bind="$attrs" v-on="$listeners">
		<font-awesome-icon :icon="['fas', 'bars']"></font-awesome-icon>
	</ej-button>
</template>

<script>
	import EjButton from "@/components/EjButton";

	export default {
		name: "EjMenuButton",

		methods: {
			openMenu() {
				this.$store.dispatch(`menu/openMenu`);
			}
		},

		components: {
			EjButton
		}
	}
</script>

<style scoped>

</style>