<template>
	<div class="ej-mobile-menu" ref="menu" v-bind="$attrs" v-on="$listeners">
		<div class="content">
			<div class="profile">
				<img class="img-fluid rounded-circle" src="/img/default-avatar.png"/><br>
				<span>{{ $store.state.auth.user.name }}</span><br>
				<strong>{{ $store.state.auth.user.email }}</strong>
			</div>

			<ul>
				<li :class="{ active: $route.name === 'home' }" @click="goTo('home')">
					<font-awesome-icon :icon="['fas', 'home']"></font-awesome-icon> Home
				</li>
                <li v-if="$store.state.auth.permissions['settings.updateAny']" :class="{ active: $route.name === 'settings' }" @click="goTo('settings')">
                    <font-awesome-icon :icon="['fas', 'cog']"></font-awesome-icon> Configurações
                </li>
				<li v-if="$store.state.auth.permissions['categories.viewAny']" :class="{ active: $route.name === 'categories' }" @click="goTo('categories')">
					<font-awesome-icon :icon="['fas', 'tachometer-alt']"></font-awesome-icon> Categorias de Produto
				</li>
				<li v-if="$store.state.auth.permissions['roles.viewAny']" :class="{ active: $route.name === 'roles' }" @click="goTo('roles')">
					<font-awesome-icon :icon="['fas', 'tachometer-alt']"></font-awesome-icon> Grupos de Permissão
				</li>
				<li v-if="$store.state.auth.permissions['monitoring.viewAny']" :class="{ active: $route.name === 'monitoring' }" @click="goTo('monitoring')">
					<font-awesome-icon :icon="['fas', 'tachometer-alt']"></font-awesome-icon> Monitoramento
				</li>
				<li v-if="$store.state.auth.permissions['view_any_order'] || $store.state.auth.permissions['view_your_own_orders']" :class="{ active: $route.name === 'orders' }" @click="goTo('orders')">
					<font-awesome-icon :icon="['fas', 'shopping-cart']"></font-awesome-icon> Pedidos
				</li>
				<li v-if="$store.state.auth.permissions['people.viewAny'] || $store.state.auth.permissions['people.viewOwn']" :class="{ active: $route.name === 'people' }" @click="goTo('people')">
					<font-awesome-icon :icon="['fas', 'users']"></font-awesome-icon> Pessoas
				</li>
				<li v-if="$store.state.auth.permissions['products.viewAny']" :class="{ active: $route.name === 'products' }" @click="goTo('products')">
					<font-awesome-icon :icon="['fas', 'boxes']"></font-awesome-icon> Produtos
				</li>
				<li v-if="$store.state.auth.permissions['users.viewAny']" :class="{ active: $route.name === 'users' }" @click="goTo('users')">
					<font-awesome-icon :icon="['fas', 'user-lock']"></font-awesome-icon> Usuários
				</li>
				<li @click="logout">
					<font-awesome-icon :icon="['fas', 'sign-out-alt']"></font-awesome-icon> Sair <font-awesome-icon :icon="['fas', 'cog']" spin v-if="loggingOut"></font-awesome-icon>
				</li>
			</ul>
		</div>
		<div class="background" ref="background" @click="closeMenu"></div>
	</div>
</template>

<script>
	import Hammer from "hammerjs";

	export default {
		name: "EjMobileMenu",

		data() {
			return {
				loggingOut: false,
				hammer: null,
			}
		},

		watch: {
			"$store.state.menu.transitionEnabled": function(newvalue) {
				if (newvalue)
					this.$refs.menu.style.transition = "300ms";
				else
					this.$refs.menu.style.transition = "none";
			},

			"$store.state.menu.x": function(newValue) {
				if (newValue === -300) {
					if (this.$refs.menu.classList.contains('open'))
						this.$refs.menu.classList.remove('open');
				} else {
					if (!this.$refs.menu.classList.contains('open'))
						this.$refs.menu.classList.add('open');
				}

				this.$refs.menu.style.left = `${newValue}px`;
				const maxOpacity = 0.3;
				const opacity = (maxOpacity - (maxOpacity / 300 * newValue * -1)).toFixed(4);
				this.$refs.background.style.backgroundColor = `rgba(0,0,0,${opacity})`;
			}
		},

		methods: {
			async logout() {
				this.loggingOut = true;
				await this.$store.dispatch(`auth/logout`);
				this.loggingOut = false;
			},

			instanciateHammer() {
				delete Hammer.defaults.cssProps.userSelect;
				const hammer = new Hammer(document.body);
				hammer.get('swipe').set({
					direction: Hammer.DIRECTION_HORIZONTAL,
					threshold: 0
				});

				return hammer;
			},

			goTo(route) {
				this.$router.push({ name: route });
				this.closeMenu();
			},

			closeMenu() {
				this.$store.dispatch(`menu/closeMenu`);
			}
		},

		mounted() {
			const vm = this;

			this.hammer = this.instanciateHammer();
			this.hammer.on("pan", function(e) {
				vm.$store.dispatch(`menu/setTransitionEnabled`, false);

				if (!vm.isDragging) {
					vm.isDragging = true;
					vm.$store.dispatch(`menu/setLastX`, vm.$refs.menu.offsetLeft);
				}

				let newX = e.deltaX + vm.$store.state.menu.lastX;
				if (newX < -300)
					vm.$store.dispatch(`menu/setX`, -300);
				else if (newX > 0)
					vm.$store.dispatch(`menu/setX`, 0);
				else
					vm.$store.dispatch(`menu/setX`, newX);

				if (e.isFinal) {
					if (e.direction === Hammer.DIRECTION_RIGHT && newX > -275)
						vm.$store.dispatch(`menu/openMenu`);
					else
						vm.$store.dispatch(`menu/closeMenu`);

					vm.isDragging = false;
				}
			});
		}
	}
</script>

<style scoped>
	.profile img {
		width: 100px;
		margin-bottom: 10px;
	}

	.profile {
		text-align: center;
		width: 280px;
		margin: 0 auto;
		padding-bottom: 15px;
	}

	.ej-mobile-menu {
		position: fixed;
		height: 100%;
		top: 0;
		left: -300px;
		z-index: 52;
	}

	.ej-mobile-menu.open > .content {
		box-shadow: rgb(146 146 146) 1px 0 10px;
	}

	.ej-mobile-menu.open > .background {
		display: block;
	}

	.ej-mobile-menu > .content {
		background: #f9f9f9;
		width: 300px;
		height: 100%;
		position: relative;
		box-shadow: none;
		padding-top: 30px;
		z-index: 54;
	}

	.ej-mobile-menu ul {
		padding-left: 30px;
	}

	.ej-mobile-menu ul li {
		list-style: none;
		height: 45px;
		line-height: 45px;
		font-size: 20px;
	}

	.ej-mobile-menu ul li.active {
		font-weight: 700;
		color: #585858;
	}

	.ej-mobile-menu ul li svg {
		width: 30px;
	}

	.background {
		display: none;
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 53;
		left: 0;
		top: 0;
	}

	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) {
		.ej-mobile-menu {
			display: none;
		}
	}
</style>
