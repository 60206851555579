import store from "@/store";

const routes = [
	{
		path: '/products',
		name: 'products',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "products" */ '../../views/products'),
		meta: {
			title: 'Produtos'
		}
	},
	{
		path: '/products/create',
		name: 'products.create',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "products.edit" */ '../../views/products/edit'),
		meta: {
			title: 'Criar Produto'
		}
	},
	{
		path: '/products/:id',
		name: 'products.edit',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "products.edit" */ '../../views/products/edit'),
		meta: {
			title: 'Editar Produto'
		}
	},
	{
		path: '/products/:id/composition',
		name: 'products.composition',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "products.composition" */ '../../views/products/composition'),
		meta: {
			title: 'Composição do Produto'
		}
	}
];

export default routes;
