<template>
	<footer class="d-flex justify-content-center">
		<div class="container d-flex justify-content-center">
			<div class="row d-flex align-self-center">
				<div class="col-12 text-center">
					<span>Desenvolvido por <a id="link-ejetar" href="https://grupoejetar.com.br">Ejetar <img src="/img/logo-ejetar.png" class="img-fluid" alt=""></a></span>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		name: "EjFooter"
	}
</script>

<style scoped>
	#link-ejetar {
		text-decoration: none;
	}

	#link-ejetar {
		filter: grayscale(1);
		opacity: 0.5;
	}

	#link-ejetar:hover {
		filter: none;
		opacity: 1;
	}
</style>