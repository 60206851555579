import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import AutoMessages from './routes/automessages';
import Categories from './routes/categories';
import Orders from './routes/orders';
import Passwords from './routes/passwords';
import People from './routes/people';
import Products from './routes/products';
import Roles from './routes/roles';
import Users from './routes/users';
import Settings from './routes/settings';

import store from "@/store";

const routes = [
	{
		path: '/',
		name: 'home',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "home" */ '../views/home/index'),
		meta: {
			title: 'Home',
			layout: 'default'
		}
	},
	{
		path: '/login',
		name: 'login',
		component: () => import(/* webpackChunkName: "login" */ '../views/login/index'),
		meta: {
			title: 'Login',
			layout: 'login'
		}
	},
	...AutoMessages,
	...Categories,
	...Orders,
	...Passwords,
	...People,
	...Products,
	...Roles,
	...Settings,
	...Users,
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
