const vueDatePicker = {
	nextMonthCaption: 'Próximo mês',
	prevMonthCaption: 'Mês anterior',
	setTimeCaption: 'Editar data:',
	weekdays: [
		'Seg',
		'Ter',
		'Qua',
		'Qui',
		'Sex',
		'Sáb',
		'Dom'
	],
	months: [
		'Janeiro',
		'Fevereiro',
		'Março',
		'Abril',
		'Maio',
		'Junho',
		'Julho',
		'Agosto',
		'Setembro',
		'Outubro',
		'Novembro',
		'Dezembro'
	]
};

export { vueDatePicker };