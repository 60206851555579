import store from "@/store";

const routes = [
	{
		path: '/users',
		name: 'users',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "users" */ '../../views/users'),
		meta: {
			title: 'Usuários'
		}
	}
];

export default routes;
