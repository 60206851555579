const _ = require('lodash');

export default {
	namespaced: true,

	actions: {
		index(state, params) {
			state; //fixme ver se tem alguma forma de resolver isso

			const searchParams = new URLSearchParams(params);
			return window.axios.get(`/settings?${searchParams.toString()}`);
		},

		patch(state, params) {
			state; //fixme ver se tem alguma forma de resolver isso

			let query = _.get(params,'query') ? params.query : '';
			const searchParams = new URLSearchParams(Object.assign(window.defaultQueryParams, query));

			return window.axios.patch(`/settings/${params.id}?${searchParams.toString()}`, params.data);
		},
	}
}
