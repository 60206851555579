export default {
	namespaced: true,

	state: {
		x: -300,
		lastX: -300,
		isDragging: false,
		transitionEnabled: false
	},

	mutations: {
		setX: (state, x) => {
			state.x = x;
		},

		setLastX: (state, lastX) => {
			state.lastX = lastX;
		},

		setTransitionEnabled: (state, enabled) => {
			state.transitionEnabled = enabled;
		},
	},

	actions: {
		openMenu({ commit }) {
			// this.$refs.menu.style.transition = "300ms";
			commit('setTransitionEnabled', true);
			commit('setX', 0);
		},

		closeMenu({ commit }) {
			// this.$refs.menu.style.transition = "300ms";
			commit('setTransitionEnabled', true);
			commit('setX', -300);
		},

		setX({ commit }, x) {
			commit('setX', x);
		},

		setLastX({ commit }, lastX) {
			commit('setLastX', lastX);
		},

		setTransitionEnabled({ commit }, enabled) {
			commit('setTransitionEnabled', enabled);
		}
	}
}