import {exibeErro} from "@/helpers";
const _ = require('lodash');

export default {
	namespaced: true,

	state: {
		users: undefined,
		fetching: false,

		//Filtros
		filtering: {
			enabled: true,
			opened: false,

			keyword: "",

			selected: {
				field: 'users.name',
				criteria: ""
			},

			fields: [
				{
					name: "Criado em",
					value: 'users.created_at'
				},
				{
					name: "E-mail",
					value: 'users.email'
				},
				{
					name: "Id",
					value: 'users.id'
				},
				{
					name: "Nome",
					value: 'users.name'
				},
			],
			criterias: [
				{
					name: "Contém",
					value: ""
				},
			]
		},

		//Ordenação
		sorting: {
			enabled: true,
			opened: false,

			selected: {
				field: 'users.name',
				direction: 'ascending'
			},

			fields: [
				{
					name: "Atualizado em",
					value: 'users.updated_at'
				},
				{
					name: "Criado em",
					value: 'users.created_at'
				},
				{
					name: "E-mail",
					value: 'users.email'
				},
				{
					name: "Id",
					value: 'users.id'
				},
				{
					name: "Nome",
					value: 'users.name'
				},
			],
			directions: [
				{
					name: "Crescente",
					value: 'ascending'
				},
				{
					name: "Decrescente",
					value: 'descending'
				}
			]
		},

		//Paginação
		pagination: {
			current_page: 1,
			last_page: undefined,
		},
	},

	mutations: {
		//Pagination
		setPage: (state, page) => {
			state.pagination.current_page = page;
		},
		setLastPage: (state, page) => {
			state.pagination.last_page = page;
		},

		//Sorting
		setSortingField: (state, value) => {
			state.sorting.selected.field = value;
		},
		setSortingDirection: (state, value) => {
			state.sorting.selected.direction = value;
		},
		setSortingOpened: (state) => {
			state.sorting.opened = !state.sorting.opened;
		},

		//Sorting
		setFilteringField: (state, value) => {
			state.filtering.selected.field = value;
		},
		setFilteringCriteria: (state, value) => {
			state.filtering.selected.criteria = value;
		},
		setFilteringKeyword: (state, value) => {
			state.filtering.keyword = value;
		},
		setFilteringOpened: (state) => {
			state.filtering.opened = !state.filtering.opened;
		},

		//Others
		setUsers: (state, users) => {
			state.users = users;
		},
		setFetching: (state, status) => {
			state.fetching = status;
		},
	},

	actions: {
		index(state, params) {
			state; //fixme ver se tem alguma forma de resolver isso

			const searchParams = new URLSearchParams(params);
			return window.axios.get(`/users?${searchParams.toString()}`);
		},
		delete(state, id) {
			state; //fixme ver se tem alguma forma de resolver isso

			return window.axios.delete(`/users/${id}`);
		},
		get(state, id, params) {
			state; //fixme ver se tem alguma forma de resolver isso

			const searchParams = new URLSearchParams(params);
			return window.axios.get(`/users/${id}?${searchParams.toString()}`);
		},
		patch(state, params) {
			state; //fixme ver se tem alguma forma de resolver isso

			let query = _.get(params,'query') ? params.query : '';
			const searchParams = new URLSearchParams(Object.assign(window.defaultQueryParams, query));

			return window.axios.patch(`/users/${params.id}?${searchParams.toString()}`, params.data);
		},
		post(state, data, params) {
			state; //fixme ver se tem alguma forma de resolver isso

			const searchParams = new URLSearchParams(Object.assign(window.defaultQueryParams, params));
			return window.axios.post(`/users?${searchParams.toString()}`, data);
		},
		me() {
			return window.axios.get(`/users/me`);
		},

		//Sorting
		setSortingField({ commit }, value) {
			commit('setSortingField', value);
		},
		setSortingDirection({ commit }, value) {
			commit('setSortingDirection', value);
		},
		setSortingOpened({ commit }) {
			commit('setSortingOpened');
		},

		//Filtering
		setFilteringField({ commit }, value) {
			commit('setFilteringField', value);
		},
		setFilteringCriteria({ commit }, value) {
			commit('setFilteringCriteria', value);
		},
		setFilteringKeyword({ commit }, value) {
			commit('setFilteringKeyword', value);
		},
		setFilteringOpened({ commit }) {
			commit('setFilteringOpened');
		},

		//Paginação
		setPage({ commit }, value) {
			commit('setPage', value);
		},

		//Others
		fetch({ dispatch, commit, state }) {
			commit('setFetching', true);

			const aux = {
				page: state.pagination.current_page,
				sort: (state.sorting.selected.direction === 'ascending' ? '' : '-') + state.sorting.selected.field
			};
			aux[`filter[${state.filtering.selected.field}]`] = state.filtering.keyword;

			const searchParams = new URLSearchParams(aux);

			return dispatch(`index`, searchParams.toString())
				.then(function (response) {
					commit('setUsers', response.data.data);
					commit('setLastPage', response.data.last_page);
				})
				.catch(function (error) {
					// handle error
					exibeErro(error);
				})
				.then(function () {
					// always executed
					commit('setFetching', false);
				});
		}
	}
}
