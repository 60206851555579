import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker'

//Routing
import router from './router';

//Vuex
import store from './store';

//Page Title
import VuePageTitle from 'vue-page-title';
Vue.use(VuePageTitle, { router });

//Icons
import FontAwesomeIcon from './icons';
Vue.component('font-awesome-icon', FontAwesomeIcon);

//Lodash
import _ from 'lodash';
Vue.prototype._ = _;

//Componentes globais
import EjPage from "@/components/EjPage";
import EjBackButton from "@/components/EjBackButton";
import EjBlankPage from "@/components/EjBlankPage";
import EjBreadcrumb from "@/components/EjBreadcrumb";
import EjButton from "@/components/EjButton";
import EjButtons from "@/components/EjButtons";
import EjContent from "@/components/EjContent";
import EjDatePicker from "@/components/EjDatePicker";
import EjDesktopHeader from "@/components/EjDesktopHeader";
import EjFloatingButton from "@/components/EjFloatingButton";
import EjFooter from "@/components/EjFooter";
import EjMenuButton from "@/components/EjMenuButton";
import EjMobileHeader from "@/components/EjMobileHeader";
import EjMobileMenu from "@/components/EjMobileMenu";
import EjModal from "@/components/EjModal";
import EjOrderIcon from "@/components/EjOrderIcon";
import EjSelect from "@/components/EjSelect";
import EjTitle from "@/components/EjTitle";
import EjToggleButton from "@/components/EjToggleButton";
import EjToolbar from "@/components/EjToolbar";

//Carrega componentes globais
[
	EjAsterisk,
	EjBackButton,
	EjBlankPage,
	EjBreadcrumb,
	EjButton,
	EjButtons,
	EjContent,
	EjDatePicker,
	EjDesktopHeader,
	EjFloatingButton,
	EjFooter,
	EjMenuButton,
	EjMobileHeader,
	EjMobileMenu,
	EjModal,
	EjOrderIcon,
	EjPage,
	EjSelect,
	EjTitle,
	EjToggleButton,
	EjToolbar
].forEach(component => {
	Vue.component(component.name, component);
});

window.axios = require('axios');
window.axios.defaults.withCredentials = true;
window.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

//i18n
Vue.prototype.i18n = require('@/i18n');

//moment
import moment from 'moment';
Vue.prototype.moment = moment;

//Global variables
Vue.prototype.$dropzoneOptions = {
	acceptedFiles: "image/*",
	progress: true,
	addRemoveLinks: false,
	dictDefaultMessage: "<p><strong>Clique aqui</strong> para tirar uma foto ou carregar uma imagem da galeria</p><p>ou</p><p><strong>Arraste e solte</strong> o(s) arquivo(s) aqui</p>",
	dictFallbackMessage: "Seu navegador não suporta uploads de arrastar e soltar.",
	dictFallbackText: "Por favor, use o formulário abaixo para enviar seus arquivos como antigamente.",
	dictFileTooBig: "O arquivo é muito grande ({{filesize}}MB). Tamanho máximo permitido: {{maxFilesize}}MB.",
	dictInvalidFileType: "Você não pode fazer upload de arquivos desse tipo.",
	dictResponseError: "O servidor respondeu com o código {{statusCode}}.",
	dictCancelUpload: "Cancelar envio",
	dictCancelUploadConfirmation: "Tem certeza de que deseja cancelar este envio?",
	dictRemoveFile: "Remover arquivo",
	dictMaxFilesExceeded: "Você só pode fazer upload de {{maxFiles}} arquivos.",
	url: '/',
	method: 'put',
	chunking: true,
	forceChunking: true,
	autoQueue: false,
	autoProcessQueue: false
};

//Currency Input
import VueCurrencyInput from 'vue-currency-input';
Vue.use(VueCurrencyInput, {
	globalOptions: {
		autoDecimalMode: true,
		precision: 2,
		currency: 'BRL',
		distractionFree: false
	}
});

//v-money
import money from 'v-money'
Vue.use(money, {precision: 4})

//Toggle Button
import ToggleButton from 'vue-js-toggle-button';
Vue.use(ToggleButton)

//Mask
import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

//Diretivas personalizadas
import lowercase from "@/diretives/lowercase";
import uppercase from "@/diretives/uppercase";
Vue.directive('lowercase', lowercase);
Vue.directive('uppercase', uppercase);

import * as VueDeepSet from 'vue-deepset';
Vue.use(VueDeepSet);

//v-tooltip
import VTooltip from 'v-tooltip';
import EjAsterisk from "@/components/EjAsterisk";
Vue.use(VTooltip);

String.prototype.ucwords = function(str) {
	str = this.toLowerCase();
	return str.replace(/(^([a-zA-Z\p{M}]))|([ -][a-zA-Z\p{M}])/g,
		function(s){
			return s.toUpperCase();
		});
};

if (process.env.NODE_ENV === 'development') //fixme
	window.defaultQueryParams = {XDEBUG_SESSION_START: ""};
else
	window.defaultQueryParams = {};

import Toasted from 'vue-toasted';
Vue.use(Toasted, {
	position: 'top-left',
	duration: 2000
});

Vue.config.productionTip = false
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
