<template>
	<div class="ej-toolbar" v-bind="$attrs" v-on="$listeners">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: "EjToolbar"
	}
</script>

<style scoped>
	.ej-toolbar {
		display: flex;
		align-items: center;
		width: 100%;
	}
</style>