import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//Módulos
import Menu from '@/store/modules/menu';
import Auth from '@/store/modules/auth';
import AutoMessages from '@/store/modules/automessages';
import AutoMessageMailings from '@/store/modules/mailings';
import Carriers from '@/store/modules/carriers';
import Categories from '@/store/modules/categories';
import Cities from '@/store/modules/cities';
import Customers from '@/store/modules/customers';
import Orders from '@/store/modules/orders';
import Passwords from '@/store/modules/passwords';
import People from '@/store/modules/people';
import Products from '@/store/modules/products';
import Resellers from '@/store/modules/resellers';
import Roles from '@/store/modules/roles';
import Sellers from '@/store/modules/sellers';
import Settings from '@/store/modules/settings';
import States from '@/store/modules/states';
import Users from '@/store/modules/users';

export default new Vuex.Store({
	modules: {
		'menu': Menu,
		'auth': Auth,
		'automessages': AutoMessages,
		'automessage_mailings': AutoMessageMailings,
		'carriers': Carriers,
		'categories': Categories,
		'cities': Cities,
		'customers': Customers,
		'orders': Orders,
		'passwords': Passwords,
		'people': People,
		'products': Products,
		'resellers': Resellers,
		'roles': Roles,
		'sellers': Sellers,
		'settings': Settings,
		'states': States,
		'users': Users,
	},

	state: {},
	mutations: {},
	actions: {}
})
