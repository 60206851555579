<template>
	<div class="ej-title h5 font-weight-bold" v-bind="$attrs" v-on="$listeners">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: "EjTitle"
	}
</script>

<style scoped>
	.h5 {
		margin: 0;
	}

	.ej-title {
		line-height: inherit;
	}
</style>