<template>
	<div class="ej-blank-page" v-bind="$attrs" v-on="$listeners">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: "EjBlankPage"
	}
</script>

<style scoped>

</style>