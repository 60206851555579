import {exibeErro} from "@/helpers";

export default {
	namespaced: true,

	state: {
		automessage: undefined,
		automessage_mailings: undefined,
		fetching: false,

		//Filtros
		filtering: {
			enabled: true,
			opened: false,

			keyword: "",

			selected: {
				field: 'automessage_mailings.id',
				criteria: ""
			},

			fields: [
				{
					name: "Id",
					value: 'automessage_mailings.id'
				},
			],
			criterias: [
				{
					name: "Contém",
					value: ""
				},
				// {
				// 	name: "Diferente (!=)",
				// 	value: "!="
				// },
				// {
				// 	name: "Igual (==)",
				// 	value: "=="
				// },
				// {
				// 	name: "Maior (>)",
				// 	value: ">"
				// },
				// {
				// 	name: "Maior ou igual (>=)",
				// 	value: ">="
				// },
				// {
				// 	name: "Menor (<)",
				// 	value: "<"
				// },
				// {
				// 	name: "Menor ou igual (<=)",
				// 	value: "<="
				// },
			],
		},

		//Ordenação
		sorting: {
			enabled: true,
			opened: false,

			selected: {
				field: 'automessage_mailings.id',
				direction: 'ascending'
			},

			fields: [
				{
					name: "Atualizado em",
					value: 'automessage_mailings.updated_at'
				},
				{
					name: "Criado em",
					value: 'automessage_mailings.created_at'
				},
				{
					name: "Id",
					value: 'automessage_mailings.id'
				},
			],
			directions: [
				{
					name: "Crescente",
					value: 'ascending'
				},
				{
					name: "Decrescente",
					value: 'descending'
				}
			]
		},

		//Paginação
		pagination: {
			current_page: 1,
			last_page: undefined,
		},
	},

	mutations: {
		//Pagination
		setPage: (state, page) => {
			state.pagination.current_page = page;
		},
		setLastPage: (state, page) => {
			state.pagination.last_page = page;
		},

		//Sorting
		setSortingField: (state, value) => {
			state.sorting.selected.field = value;
		},
		setSortingDirection: (state, value) => {
			state.sorting.selected.direction = value;
		},
		setSortingOpened: (state) => {
			state.sorting.opened = !state.sorting.opened;
		},

		//Sorting
		setFilteringField: (state, value) => {
			state.filtering.selected.field = value;
		},
		setFilteringCriteria: (state, value) => {
			state.filtering.selected.criteria = value;
		},
		setFilteringKeyword: (state, value) => {
			state.filtering.keyword = value;
		},
		setFilteringOpened: (state) => {
			state.filtering.opened = !state.filtering.opened;
		},

		//Others
		setData: (state, automessage_mailings) => {
			state.automessage_mailings = automessage_mailings;
		},
		setFetching: (state, status) => {
			state.fetching = status;
		},
		setAutomessage: (state, id) => {
			console.log('mutation:setAutomessage', id)
			state.automessage = id;
		},
	},

	actions: {
		//REST Endpoints
		index({state}, params) {
			console.log('action:index')

			const searchParams = new URLSearchParams(params);
			return window.axios.get(`/automessages/${state.automessage}/mailings?${searchParams.toString()}`);
		},

		//Sorting
		setSortingField({ commit }, value) {
			commit('setSortingField', value);
		},
		setSortingDirection({ commit }, value) {
			commit('setSortingDirection', value);
		},
		setSortingOpened({ commit }) {
			commit('setSortingOpened');
		},

		//Filtering
		setFilteringField({ commit }, value) {
			commit('setFilteringField', value);
		},
		setFilteringCriteria({ commit }, value) {
			commit('setFilteringCriteria', value);
		},
		setFilteringKeyword({ commit }, value) {
			commit('setFilteringKeyword', value);
		},
		setFilteringOpened({ commit }) {
			commit('setFilteringOpened');
		},

		//Paginação
		setPage({ commit }, value) {
			commit('setPage', value);
		},

		//Others
		fetch({ dispatch, commit, state }) {
			console.log('action:fetch')

			commit('setFetching', true);

			const aux = {
				page: state.pagination.current_page,
				sort: (state.sorting.selected.direction === 'ascending' ? '' : '-') + state.sorting.selected.field
			};
			aux[`filter[${state.filtering.selected.field}]`] = state.filtering.keyword;

			return dispatch(`index`, aux)
				.then(function (response) {
					commit('setData', response.data.data);
					commit('setLastPage', response.data.last_page);
				})
				.catch(function (error) {
					// handle error
					exibeErro(error);
				})
				.then(function () {
					// always executed
					commit('setFetching', false);
				});
		},

		setAutomessage({ commit }, value) {
			console.log('action:setAutomessage',value)

			commit('setAutomessage', value);
		},
	}
}
