import {exibeErro} from "@/helpers";
const _ = require('lodash');

export default {
	namespaced: true,

	state: {
		orders: undefined,
		fetching: false,

		//Filtros
		filtering: {
			enabled: true,
			opened: false,

			keyword: "",

			selected: {
				field: 'full',
				criteria: ""
			},

			fields: [
				{
					name: "Cidade (do cliente)",
					value: 'city.name'
				},
				{
					name: "Cliente",
					value: 'customer.name'
				},
				{
					name: "Cód. Externo",
					value: 'orders.code'
				},
				{
					name: "CPF/CNPJ (do cliente)",
					value: 'customer.document'
				},
				{
					name: "Criado em",
					value: 'orders.created_at'
				},
				{
					name: "Data",
					value: 'orders.date'
				},
				{
					name: "Emitente",
					value: 'seller.name'
				},
				{
					name: "Id",
					value: 'orders.id'
				},
				{
					name: "Indicador",
					value: 'indicator.name'
				},
				{
					name: "Revenda",
					value: 'reseller.name'
				},
				{
					name: "Status",
					value: 'orders.status'
				},
				{
					name: "Total",
					value: 'orders.total'
				},
				{
					name: "UF (do cliente)",
					value: 'state.acronym'
				},
				{
					name: "-- TODOS --",
					value: 'full'
				},
			],
			criterias: [
				{
					name: "Contém",
					value: ""
				},
				// {
				// 	name: "Diferente (!=)",
				// 	value: "!="
				// },
				// {
				// 	name: "Igual (==)",
				// 	value: "=="
				// },
				// {
				// 	name: "Maior (>)",
				// 	value: ">"
				// },
				// {
				// 	name: "Maior ou igual (>=)",
				// 	value: ">="
				// },
				// {
				// 	name: "Menor (<)",
				// 	value: "<"
				// },
				// {
				// 	name: "Menor ou igual (<=)",
				// 	value: "<="
				// },
			],
		},

		//Ordenação
		sorting: {
			enabled: true,
			opened: false,

			selected: {
				field: 'orders.id',
				direction: 'descending'
			},

			fields: [
				{
					name: "Atualizado em",
					value: 'orders.updated_at'
				},
				{
					name: "Cidade (do cliente)",
					value: 'city.name'
				},
				{
					name: "Cliente",
					value: 'customer.name'
				},
				{
					name: "Cód. Externo",
					value: 'orders.code'
				},
				{
					name: "CPF/CNPJ (do cliente)",
					value: 'customer.document'
				},
				{
					name: "Criado em",
					value: 'orders.created_at'
				},
				{
					name: "Data",
					value: 'orders.date'
				},
				{
					name: "Emitente",
					value: 'seller.name'
				},
				{
					name: "Id",
					value: 'orders.id'
				},
				{
					name: "Indicador",
					value: 'indicator.name'
				},
				{
					name: "Revenda",
					value: 'reseller.name'
				},
				{
					name: "Status",
					value: 'orders.status'
				},
				{
					name: "Total",
					value: 'orders.total'
				},
				{
					name: "UF (do cliente)",
					value: 'state.acronym'
				}
			],
			directions: [
				{
					name: "Crescente",
					value: 'ascending'
				},
				{
					name: "Decrescente",
					value: 'descending'
				}
			]
		},

		//Paginação
		pagination: {
			current_page: 1,
			last_page: undefined,
		},
	},

	mutations: {
		//Pagination
		setPage: (state, page) => {
			state.pagination.current_page = page;
		},
		setLastPage: (state, page) => {
			state.pagination.last_page = page;
		},

		//Sorting
		setSortingField: (state, value) => {
			state.sorting.selected.field = value;
		},
		setSortingDirection: (state, value) => {
			state.sorting.selected.direction = value;
		},
		setSortingOpened: (state) => {
			state.sorting.opened = !state.sorting.opened;
		},

		//Sorting
		setFilteringField: (state, value) => {
			state.filtering.selected.field = value;
		},
		setFilteringCriteria: (state, value) => {
			state.filtering.selected.criteria = value;
		},
		setFilteringKeyword: (state, value) => {
			state.filtering.keyword = value;
		},
		setFilteringOpened: (state) => {
			state.filtering.opened = !state.filtering.opened;
		},

		//Others
		setOrders: (state, orders) => {
			state.orders = orders;
		},
		setFetching: (state, status) => {
			state.fetching = status;
		},
	},

	actions: {
		//REST Endpoints
		index(state, params) {
			state; //fixme ver se tem alguma forma de resolver isso

			const searchParams = new URLSearchParams(params);
			return window.axios.get(`/orders?${searchParams.toString()}`);
		},
		delete(state, id) {
			state; //fixme ver se tem alguma forma de resolver isso

			return window.axios.delete(`/orders/${id}`);
		},
		get(state, id, params) {
			state; //fixme ver se tem alguma forma de resolver isso

			let query = _.get(params,'query') ? params.query : '';
			const searchParams = new URLSearchParams(Object.assign(window.defaultQueryParams, query));

			return window.axios.get(`/orders/${id}?${searchParams.toString()}`);
		},
		patch(state, params) {
			state; //fixme ver se tem alguma forma de resolver isso

			let query = _.get(params,'query') ? params.query : '';
			const searchParams = new URLSearchParams(Object.assign(window.defaultQueryParams, query));

			return window.axios.patch(`/orders/${params.id}?${searchParams.toString()}`, params.data);
		},
		post(state, data, params) {
			state; //fixme ver se tem alguma forma de resolver isso

			const searchParams = new URLSearchParams(Object.assign(window.defaultQueryParams, params));
			return window.axios.post(`/orders?${searchParams.toString()}`, data);
		},

		send_email_copy(state, params) {
			const searchParams = new URLSearchParams(window.defaultQueryParams);
			return window.axios.post(`/orders/${params.id}/send_email_copy?${searchParams.toString()}`,params.data);
		},

		cancel(state, id) {
			const searchParams = new URLSearchParams(window.defaultQueryParams);
			return window.axios.delete(`/orders/${id}/cancel?${searchParams.toString()}`);
		},

		duplicate(state, id) {
			const searchParams = new URLSearchParams(window.defaultQueryParams);
			return window.axios.get(`/orders/${id}/duplicate?${searchParams.toString()}`);
		},

		reject(state, id) {
			const searchParams = new URLSearchParams(window.defaultQueryParams);
			return window.axios.get(`/orders/${id}/reject?${searchParams.toString()}`);
		},

		launch(state, id) {
			const searchParams = new URLSearchParams(window.defaultQueryParams);
			return window.axios.get(`/orders/${id}/launch?${searchParams.toString()}`);
		},

		release(state, id) {
			const searchParams = new URLSearchParams(window.defaultQueryParams);
			return window.axios.get(`/orders/${id}/release?${searchParams.toString()}`);
		},

		new(state, id) {
			const searchParams = new URLSearchParams(window.defaultQueryParams);
			return window.axios.get(`/orders/${id}/new?${searchParams.toString()}`);
		},

		deliver(state, id) {
			const searchParams = new URLSearchParams(window.defaultQueryParams);
			return window.axios.get(`/orders/${id}/deliver?${searchParams.toString()}`);
		},

		produce(state, id) {
			const searchParams = new URLSearchParams(window.defaultQueryParams);
			return window.axios.get(`/orders/${id}/produce?${searchParams.toString()}`);
		},

		refresh(state, id) {
			const searchParams = new URLSearchParams(window.defaultQueryParams);
			return window.axios.get(`/orders/${id}/refresh?${searchParams.toString()}`);
		},

		ship(state, id) {
			const searchParams = new URLSearchParams(window.defaultQueryParams);
			return window.axios.get(`/orders/${id}/ship?${searchParams.toString()}`);
		},

		//Sorting
		setSortingField({ commit }, value) {
			commit('setSortingField', value);
		},
		setSortingDirection({ commit }, value) {
			commit('setSortingDirection', value);
		},
		setSortingOpened({ commit }) {
			commit('setSortingOpened');
		},

		//Filtering
		setFilteringField({ commit }, value) {
			commit('setFilteringField', value);
		},
		setFilteringCriteria({ commit }, value) {
			commit('setFilteringCriteria', value);
		},
		setFilteringKeyword({ commit }, value) {
			commit('setFilteringKeyword', value);
		},
		setFilteringOpened({ commit }) {
			commit('setFilteringOpened');
		},

		//Paginação
		setPage({ commit }, value) {
			commit('setPage', value);
		},

		//Others
		fetch({ dispatch, commit, state }) {
			commit('setFetching', true);

			const aux = {
				page: state.pagination.current_page,
				sort: (state.sorting.selected.direction === 'ascending' ? '' : '-') + state.sorting.selected.field
			};
			aux[`filter[${state.filtering.selected.field}]`] = state.filtering.keyword;

			const searchParams = new URLSearchParams(aux);

			return dispatch(`index`, searchParams.toString())
				.then(function (response) {
					commit('setOrders', response.data.data);
					commit('setLastPage', response.data.last_page);
				})
				.catch(function (error) {
					// handle error
					exibeErro(error);
				})
				.then(function () {
					// always executed
					commit('setFetching', false);
				});
		}
	}
}
