import store from "@/store";

const routes = [
	{
		path: '/categories',
		name: 'categories',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "categories" */ '../../views/categories'),
		meta: {
			title: 'Categorias'
		}
	},
	{
		path: '/categories/create',
		name: 'categories.create',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "categories.edit" */ '../../views/categories/edit'),
		meta: {
			title: 'Criar Categoria'
		}
	},
	{
		path: '/categories/:id',
		name: 'categories.edit',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "categories.edit" */ '../../views/categories/edit'),
		meta: {
			title: 'Editar Categoria'
		}
	}
];

export default routes;
