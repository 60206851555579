<template>
	<button class="ej-floating-button btn btn-secondary hvr-pulse" v-bind="$attrs" v-on="$listeners">
		<slot></slot>
	</button>
</template>

<script>
	export default {
		name: "EjFloatingButton"
	}
</script>

<style scoped>
	.ej-floating-button {
		height: 60px;
		width: 60px;
		border-radius: 45px;
		border: 0;
		position: fixed;
		bottom: 20px;
		right: 20px;
		z-index: 51;
	}
</style>