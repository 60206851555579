<template>
	<div class="ej-desktop-header" v-bind="$attrs" v-on="$listeners">
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="row d-flex justify-content-between">
						<div id="top-menu">
							<router-link :class="{ active: $route.name === 'home' }" :to="{ name: 'home' }">
								<div>
									<span>
										<font-awesome-icon :icon="['fas', 'home']"></font-awesome-icon>
										Home
									</span>
								</div>
							</router-link>

							<router-link v-if="$store.state.auth.permissions['view_any_order'] || $store.state.auth.permissions['view_your_own_orders']" :class="{ active: $route.name === 'orders' }" :to="{ name: 'orders' }">
								<div>
									<span>
										<font-awesome-icon :icon="['fas', 'shopping-cart']"></font-awesome-icon>
										Pedidos
									</span>
								</div>
							</router-link>

							<router-link v-if="$store.state.auth.permissions['people.viewAny'] || $store.state.auth.permissions['people.viewOwn']" :class="{ active: $route.name === 'people' }" :to="{ name: 'people' }">
								<div>
									<span>
										<font-awesome-icon :icon="['fas', 'users']"></font-awesome-icon>
										Pessoas
									</span>
								</div>
							</router-link>

							<router-link v-if="$store.state.auth.permissions['products.viewAny'] || $store.state.auth.permissions['products.viewOwn']" :class="{ active: $route.name === 'products' }" :to="{ name: 'products' }">
								<div>
									<span>
										<font-awesome-icon :icon="['fas', 'boxes']"></font-awesome-icon>
										Produtos
									</span>
								</div>
							</router-link>

							<router-link :to="'#'">
								<div>
									<span>
										<div class="dropdown">
											<button class="btn btn-primary dropdown-toggle" type="button" id="dropdownmenuOthers" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<strong> Outros</strong>
											</button>
											<div class="dropdown-menu" aria-labelledby="dropdownmenuOthers">
												<router-link v-if="$store.state.auth.permissions['settings.updateAny']" :to="{ name: 'settings' }" class="dropdown-item">Configurações</router-link>
												<router-link v-if="$store.state.auth.permissions['categories.viewAny']" :to="{ name: 'categories' }" class="dropdown-item">Categorias de Produto</router-link>
												<router-link v-if="$store.state.auth.permissions['roles.viewAny']" :to="{ name: 'roles' }" class="dropdown-item">Grupos de Permissão</router-link>
												<router-link v-if="$store.state.auth.permissions['automessages']" :to="{ name: 'automessages' }" class="dropdown-item">Mensagens Programadas</router-link>
												<router-link v-if="$store.state.auth.permissions['monitoring.viewAny']" :to="{ name: 'monitoring' }" class="dropdown-item">Monitoramento</router-link>
												<router-link v-if="$store.state.auth.permissions['users.viewAny']" :to="{ name: 'users' }" class="dropdown-item">Usuários</router-link>
											</div>
										</div>
									</span>
								</div>
							</router-link>
						</div>

						<div class="profile-button dropdown">
							<button class="btn btn-primary dropdown-toggle" type="button" id="dropdownmenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<img class="img-fluid rounded-circle" src="/img/default-avatar.png"/>
								<strong class="email">{{ $store.state.auth.user.email }}</strong>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownmenuButton">
								<router-link :to="{name:'people.edit',params: {id: $store.state.auth.user.id}}" class="dropdown-item" href="#">Meus dados</router-link>
								<button class="dropdown-item" @click="logout">
									Sair
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "EjDesktopHeader",

		data() {
			return {
				loggingOut: false
			}
		},

		methods: {
			async logout() {
				this.loggingOut = true;
				await this.$store.dispatch(`auth/logout`);
				this.loggingOut = false;
			}
		}
	}
</script>

<style scoped>
	.profile-button {
		position: relative;
		/*line-height: 50px;*/
		height: 50px;
		margin-top: 20px;
		font-weight: 700;
		padding-right: 15px;
	}

	.profile-button > button {
		background: none;
	}

	.profile-button img {
		margin-right: 5px;
		height: 28px;
		width: 28px;
	}

	.profile-button .email {
		display: inline-block;
	}

	@media (min-width: 768px) and (max-width: 1024px) {
		.profile-button .email {
			display: none;
		}
	}

	.ej-desktop-header {
		display: none;
	}

	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) {
		.ej-desktop-header {
			display: block;
			height: 70px;
			position: relative;
			background-color: #F7F7F7;
		}

		#top-menu {
			display: flex;
			align-items: center;
			height: 70px;
			padding:20px 0 0 0;
			margin: 0;
			box-shadow: inset 0 -5px 5px 0 rgba(0,0,0,0.015);
		}

		#top-menu > a {
			position: relative;
			display: flex;
			align-items: center;
			padding: 0 50px;
			list-style: none;
			height: 100%;
			font-size: 17px;
			font-weight: 700;
			text-decoration: none;
			color: #696969;
		}

		#top-menu > a:not(:first-child) {
			margin-left: -50px;
		}

		#top-menu > a > div,
		#top-menu > a > div > span {
			display: flex;
			height: 100%;
			align-items: center;
		}

		#top-menu > a.active {
			background: white;
			padding: 0;
		}

		#top-menu > a.active:before,
		#top-menu > a.active:after {
			content: '';
			height: 50px;
			width: 25px;
			background-color: #f7f7f7;
			box-shadow: inset 0 -5px 5px 0 rgba(0,0,0,0.015);
		}

		#top-menu > a.active:before {
			border-radius: 0 0 25px 0;
		}

		#top-menu > a.active:after {
			border-radius: 0 0 0 25px;
		}

		#top-menu > a.active > div {
			background-color: #f7f7f7;
		}

		#top-menu > a.active > div > span {
			background-color: white;
		}

		#top-menu > a.active > div:after {
			content: '';
			height: 50px;
			width: 25px;
			background-color: white;
			border-radius: 0 25px 0 0;
		}

		#top-menu > a.active > div:before {
			content: '';
			height: 50px;
			width: 25px;
			background-color: white;
			border-radius: 25px 0 0 0;
		}

		#top-menu a:not(.active):hover {
			color: #585858;
		}

		#top-menu .dropdown button,
		#top-menu .dropdown button:hover {
			background: none!important;
			font-size: 17px;
		}

		/*.profile-button:hover > ul,*/
		#top-menu > a > div > span:hover > ul {
			display: block;
		}

		/*.profile-button > ul:before,*/
		#top-menu > a > div > span > ul:before {
			position: absolute;
			top: -8px;
			left: 35px;
			content: '';
			width: 0;
			height: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 8px solid rgb(242,242,242);
		}

		/*.profile-button > ul,*/
		#top-menu > a > div > span > ul {
			display: none;
			position: absolute;
			top: 45px;
			left: -20px;
			margin: 0;
			background-color: rgb(242,242,242);
			width: 200px;
			z-index: 1;
			border-radius: 10px;
			padding-left: 25px;
		}

		/*.profile-button > ul > li,*/
		#top-menu > a > div > span > ul > li {
			height: 50px;
			line-height: 50px;
			list-style: none;
		}

		#top-menu > a > div > span,
		#top-menu > a > div {
			position: relative;
		}

		#top-menu > a svg {
			margin-right: 4px;
		}
	}
</style>
