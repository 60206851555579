<template>
	<multiselect
        :value="value"
        @input="change($event)"
		:searchable="true"
		:preserve-search="true"
		:clear-on-select="false"
		:internal-search="true"
		select-label=""
		selected-label="🗸"
		deselect-label="x"
		:placeholder="placeholder"
        v-bind="$attrs"
        v-on="$listeners"
	>
        <!--  -->
		<slot>
			<span slot="noOptions">{{ noOptions }}</span>
			<span slot="noResult">{{ noResult }}</span>
		</slot>
	</multiselect>
</template>

<script>
	import Multiselect from "vue-multiselect";

	export default {
		name: "EjSelect",

        props: {
            value: {
                type: [Array, Object, String]
            },
			placeholder: {
				type: String,
				default: () => 'Buscar'
			}
		},

		data() {
			return {
				noResult: 'Nenhum resultado encontrado.',
				noOptions: 'Lista vazia, digite algo.',
			}
		},

        methods: {
            change(event) {
                this.$emit('change', event);
            }
        },

        model: {
            prop: 'value',
            event: 'change'
        },

		components: {
			Multiselect
		}
	}
</script>

<style scoped>

</style>
