<template>
	<button class="ej-button" v-bind="$attrs" v-on="$listeners">
		<slot></slot>
	</button>
</template>

<script>
	export default {
		name: "EjButton"
	}
</script>

<style scoped>
	.ej-mobile-header .ej-buttons .ej-button {
		border: 0;
		background: none;
		font-size: 17px;
		border-radius: 10px;
		width: 35px;
		height: 35px;
		line-height: 35px;
		text-align: center;
	}

	.ej-mobile-header .ej-button.active {
		background: #F7F7F7;
	}
</style>