export default {
	namespaced: true,

	actions: {
		reset(state, data) {
			state; //fixme ver se tem alguma forma de resolver isso

			return window.axios.post(`/passwords/reset`, data);
		},

		request_reset(state, data) {
			return window.axios.post(`/passwords/request_reset`, data);
		},
	}
}
