import store from "@/store";

const routes = [
	{
		path: '/settings',
		name: 'settings',
		beforeEnter: (to, from, next) => store.dispatch('auth/check', next),
		component: () => import(/* webpackChunkName: "users" */ '../../views/settings/edit'),
		meta: {
			title: 'Configurações'
		}
	}
];

export default routes;
