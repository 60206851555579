<template>
	<div class="ej-breadcrumb">
		<div class="container-fluid">
			<div class="row">
				<div class="col">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb">
							<li class="breadcrumb-item">
								<a href="#">
									<font-awesome-icon :icon="['fas', 'home']"></font-awesome-icon> Home
								</a>
							</li>
							<slot></slot>
						</ol>
					</nav>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "EjBreadcrumb"
	}
</script>

<style scoped>
	.ej-breadcrumb {

	}
</style>