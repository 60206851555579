<template>
	<ej-button class="ej-back-button" @click="goBack" v-bind="$attrs" v-on="$listeners">
		<font-awesome-icon :icon="['fas', 'arrow-left']"></font-awesome-icon>
	</ej-button>
</template>

<script>
	export default {
		name: "EjBackButton",

		methods: {
			goBack() {
				this.$router.go(-1);
			}
		}
	}
</script>

<style scoped>

</style>
