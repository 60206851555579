<template>
	<div class="ej-mobile-header" v-bind="$attrs" v-on="$listeners">
		<div class="rel"></div>

		<div class="abs">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: "EjMobileHeader"
	}
</script>

<style scoped>
	.ej-mobile-header {
		display: block;
	}

	.ej-mobile-header .rel {
		position: relative;
		height: 55px;
	}

	.ej-mobile-header .abs {
		height: 55px;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		display: flex;
		align-items: center;
		background: white;
		z-index: 51;
		line-height: 55px;
		padding: 0 10px;
	}

	.ej-mobile-header .abs > .buttons {
		margin-left: auto;
		margin-right: 10px;
	}

	.ej-mobile-header .abs h4 {
		font-weight: 700;
		margin: 0;
	}

	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) {
		.ej-mobile-header {
			display: none;
		}
	}
</style>