import Swal from "sweetalert2";
const _ = require('lodash');
const sha1 = require('js-sha1');

const break_line_to_br = function(str) {
	return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
};

const exibeErro = async function(error) {
	console.error(error);

	if (Object.prototype.hasOwnProperty.call(error, 'response')) {
	// if (_.get(error, 'constructor.name') === "Error") {
		const message = _.get(error.response.data, 'message');
		const errors = _.get(error.response.data, 'errors', []);

		//Para quando retorna 403 sem uma mensagem
		if (error.response.status === 403/* && (message.trim() === "" || !message)*/) {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: "Proibido!"
			});

		//Para quando retorna 401 sem uma mensagem
		} else if (error.response.status === 401/* && (message.trim() === "" || !message)*/) {
			await Swal.fire({
				icon: 'warning',
				title: 'Oops...',
				text: "Não autenticado! Você será redirecionado para o login."
			});
			window.location = '/login';
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				html: Object.entries(errors).reduce(
					function(ac, error) {
						return ac += `${error[1].reduce( //<span style="text-decoration: underline">${error[0]}</span>:<br>
							(ac2,item) => ac2 += `* ${item}<br>`,
							""
						)}`;
					},
					`<strong>${break_line_to_br(message)}</strong><br>`
				)
			});
		}

	} else {
		Swal.fire({
			icon: 'error',
			title: 'Oops...',
			text: error
		});
	}
};

const customWatch = {
	handler() {
		this.requestData();
	},
	deep: true
};

const whatsappOrderShareLink = (order) => {
	const token = sha1(`${order.id}|pedidos`);
	const customer = _.capitalize(order.customer.type === 'legal' ? order.customer.name : order.customer.name.split(' ')[0]);
	return whatsAppLink(`Olá ${customer}! Aqui está o seu pedido de número ${order.id}. Clique no link abaixo para baixar o PDF:\n${process.env.VUE_APP_API_URL}/orders/${order.id}/share/pdf?token=${token}`);
}

const emailOrderShareLink = (order_id) => {
	const token = sha1(`${order_id}|pedidos`);
	return `mailto:?subject=Pedido ${order_id}&body=${process.env.VUE_APP_API_URL}/orders/${order_id}/share/html?token=${token}`; //todo pdf?
}

const whatsAppLink = (text, phone = '') => {
	return `https://wa.me/${phone}?text=${encodeURI(text)}`;
}

const sanitize = function (obj) {
	return _.mapValues(obj, function(v) {
		if (!v) return v;

		const type = typeof v;
		switch(type) {
			case 'string':
				// eslint-disable-next-line no-case-declarations
				const str = v.trim();
				return str === '' ? null : str;

			case 'undefined':
				//
				break;

			case 'object':
				return sanitize(v);

			default:
				return v;
		}
	});
};

const reactivePush = function(root, path, value) {
	let newRoot = root;
	let pathValue = _.get(newRoot, path);
	pathValue.push(value);

	root = Object.assign({}, root, newRoot);
};

const reactiveSet = function(root, path, value) {
	let newRoot = root;
	root = _.set(newRoot, path, value);
};

const reactiveDelete = function(root, path, index) {
	let newRoot = root;

	_.unset(newRoot, `${path}[${index}]`);

	let arr = _.get(newRoot, path);
	arr.splice(index, 1);

	root = newRoot;
};

const createImage = src => new Promise((resolve, reject) => {
	const img = new Image();
	img.onload = () => resolve(img);
	img.onerror = error => reject(error);
	img.src = src;
});

const getImageBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});

const resizeImage = (img, quality = 70, maxWidth = 300, maxHeight = 300, type = 'image/jpeg') => new Promise((resolve, reject) => {
	// create an off-screen canvas
	let canvas = document.createElement('canvas');
	let ctx = canvas.getContext('2d');

	//Calculate the size of the thumbnail, to best fit within max/width (cropspadding)
	let thumbnailScale = (img.width / img.height) > (maxWidth / maxHeight)
		? maxWidth / img.width
		: maxHeight / img.height;
	let thumbnailWidth  = img.width * thumbnailScale;
	let thumbnailHeight = img.height * thumbnailScale;

	// set its dimension to target size
	canvas.width  = thumbnailWidth;
	canvas.height = thumbnailHeight;

	// draw source image into the off-screen canvas:
	ctx.drawImage(img, 0, 0, thumbnailWidth, thumbnailHeight);

	canvas.toBlob(function(blob) {
		let thumbnail = new Image();
		thumbnail.onload = () => resolve({
			image: thumbnail,
			blob: blob
		});
		thumbnail.onerror = error => reject(error);

		// encode image to data-uri with base64 version of compressed image
		thumbnail.src = canvas.toDataURL(type, quality);
	}, type, quality);
});

export {
	customWatch,
	whatsAppLink,
	whatsappOrderShareLink,
	emailOrderShareLink,
	exibeErro,
	break_line_to_br,
	sanitize,
	reactivePush,
	reactiveSet,
	reactiveDelete,
	resizeImage,
	getImageBase64,
	createImage
};
