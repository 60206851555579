<template>
	<div v-bind="$attrs" v-on="$listeners">
		<font-awesome-icon style="color: rgba(0,0,0,0.2)" v-if="$store.state[module].sorting.selected.field !== field" :icon="['fas', 'sort']"></font-awesome-icon>
		<font-awesome-icon v-if="$store.state[module].sorting.selected.field === field && $store.state[module].sorting.selected.direction === 'ascending'" :icon="['fas', 'sort-amount-up']"></font-awesome-icon>
		<font-awesome-icon v-if="$store.state[module].sorting.selected.field === field && $store.state[module].sorting.selected.direction === 'descending'" :icon="['fas', 'sort-amount-down']"></font-awesome-icon>
	</div>
</template>

<script>
	export default {
		name: "EjOrderIcon",

		props: {
			module: [String],
			field: [String],
		},
	}
</script>

<style scoped>

</style>