<template>
	<div class="ej-modal d-flex align-items-center justify-content-center">
		<div class="container ej-modal-inner">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: "EjModal"
	}
</script>

<style scoped>
	.ej-modal {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.6);
		z-index: 1;
		padding: 50px;
	}

	.ej-modal-inner {
		background: #ffffff;
		padding: 30px;
		border-radius: 18px;
	}
</style>